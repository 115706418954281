var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":"Missions"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',{staticClass:"grey--text m-0 p-0"},[_vm._v("Track and stay up to date on client missions")])]},proxy:true},{key:"tools",fn:function(){return [(_vm.can('create_mission_lead'))?_c('PrimaryButton',{attrs:{"vIcon":"la-plus"},on:{"click":function($event){_vm.showAddForm = true}}},[_vm._v(" Add lead ")]):_vm._e()]},proxy:true}])},[_c('Table',{ref:"table",staticClass:"missions-list-table",attrs:{"query":_vm.query,"queryKey":"missionLifecycles","headers":_vm.headers,"exportOptions":{
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Summary', value: 'missionLead.summary' },
        { text: 'Client', value: 'missionLead.client.name' },
        { text: 'State', value: 'state' },
        { text: 'Created', value: 'createdAt' },
      ],
    },"listFilters":[
      {
        field: 'state',
        label: 'Stage',
        filters: {
          expression_of_interest: 'Expression of interest',
          request_for_proposal: 'Request for proposal',
          lead_stage: 'Lead',
          scoping_stage: 'Scoping',
          proposal_stage: 'Proposal',
          contracting_stage: 'Contracting',
          staffing_stage: 'Staffing',
          execution_stage: 'Execution',
          completed: 'Completed',
          archived: 'Archived',
          paused: 'Paused',
        },
      },
      {
        field: 'country',
        label: 'Country',
        queryFilter: {
          query: _vm.countriesQuery,
          itemValue: 'name',
          listKey: 'countries',
          per: 200,
          returnObject: false,
        },
        icon: 'la-flag',
      },
      {
        field: 'clientId',
        label: 'Client',
        queryFilter: {
          query: _vm.listClientsQuery,
          itemValue: 'id',
          listKey: 'clients',
          per: 200,
          returnObject: true,
        },
        icon: 'la-city',
      },
      {
        field: 'missionExecutiveId',
        label: 'Mission Executive',
        position: 'left',
        queryFilter: {
          query: _vm.consultantsSearchQuery,
          itemValue: 'id',
          listKey: 'consultants',
          returnObject: true,
          variables: { per: 10, jobTitle: 'mission executive' },
        },
        icon: 'la-user',
      },
    ],"dateFilters":[
      {
        field: 'createdAtDate',
        label: 'Created',
      },
    ],"searchFilters":[
      {
        field: 'search',
        label: 'Search',
      },
    ],"itemActions":_vm.itemActions},on:{"click:row":_vm.gotoDetailPage},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('div',{staticClass:"mission-name pt-4"},[_c('span',[_vm._v(_vm._s(item.name || "Open Lead"))])]),_c('subtitle',{staticClass:"pt-0 grey--text",staticStyle:{"max-width":"50ch"}},[_vm._v(" "+_vm._s(_vm.truncate(item.missionLead.summary, 150))+" ")])]}},{key:`item.client`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncate( item.missionLead.client ? item.missionLead.client.name : item.missionLead.clientName, 20 ))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.missionLead.client ? item.missionLead.client.name : item.missionLead.clientName))])])]}},{key:`item.collaborators`,fn:function({ item }){return [(item.collaborators.length)?[_c('UserChip',{attrs:{"user":item.collaborators[0]}})]:_c('span',{staticClass:"text-center"},[_vm._v(" n/a ")])]}},{key:`item.state`,fn:function({ item }){return [_c('state-chip',{staticStyle:{"margin-left":"0 !important"},attrs:{"color":_vm.getStateColor(item.state),"state":item.state.replaceAll('_', ' ').replace('stage', '')}})]}},{key:`item.scopeService`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.scopeService?.serviceGroup?.name || "")+" ")]}}],null,true)}),_c('FormDialog',{attrs:{"label":"Add Lead"},model:{value:(_vm.showAddForm),callback:function ($$v) {_vm.showAddForm=$$v},expression:"showAddForm"}},[_c('AddLeadForm',{attrs:{"queries":_vm.queries,"queryVars":_vm.queryVars},on:{"close":function($event){_vm.showAddForm = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }